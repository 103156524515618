import React from 'react';
import classes from "./LogoToAllPostsOfOneUser.module.css";
import socialMedia from "../../assets/images/Profile_picture_social_media_2.png";


const LogoToAllPostsOfOneUser = () => {
  return (
    <div className={classes.logoToAllPostsOfOneUser}>
       <img
          crossOrigin="anonymous" 
          src={socialMedia} 
          alt="logo"
            />
    </div>
  )
}

export default LogoToAllPostsOfOneUser;
